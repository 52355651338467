import React, { useState } from "react";
import Logo from "../img/cearco_logo.png";

// Component code
const Navigation = () => {
  const [hamburgerActive, setHamburgerActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerActive(!hamburgerActive);
    setMenuOpen(!menuOpen);

    // Return to normal state after a delay
    setTimeout(() => {
      setHamburgerActive(false);
    }, 2000); // Adjust the duration as needed
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close the menu after a delay
  };


  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 70, // Adjust the offset as needed
        behavior: "smooth",
      });
    }
  };


  return (
    <>
      {/* Overlay */}
      {menuOpen && <div className={`overlay ${menuOpen ? 'open' : ''}`} onClick={closeMenu} />}
    <nav>
      <div className="logo">
        <a href="https://www.cearcochemicals.com">
          <img src={Logo} alt="Cearco Logo" className="logo" />
        </a>
      </div>

      {/* Navigation Menu */}
      <ul className={`menu ${menuOpen ? "open" : ""}`}>
        <li>
          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("home");
              closeMenu();
            }}
          >
            Home
          </a>
        </li>
        <li>
          <a
            href="#about"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("about");
              closeMenu();
            }}
          >
            Our History
          </a>
        </li>
        <li>
          <a
            href="#product"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("product");
              closeMenu();
            }}
          >
            Products & Services
          </a>
        </li>
        <li>
          <a
            href="#contact"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("contact");
              closeMenu();
            }}
          >
            Contact
          </a>
        </li>
      </ul>
            {/* Hamburger Menu */}
      <div
        className={`hamburger ${hamburgerActive ? "active" : ""}`}
        onClick={toggleHamburger}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
    </>
  );
};

export default Navigation;
