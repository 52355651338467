// React JS libraries
import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaMailBulk,
  FaMapMarker,
  FaPhone,
} from "react-icons/fa";

// Material UI libraries
import Navigation from "./Navigation/Navigation.jsx";
import Menu from "./Menu/Menu.jsx";
import ScrollToTopButton from "./ScrollToTopButton/ScrollToTopButton.jsx";
import Card from "./Card/Card.jsx";

// Assets
import "../src/AMCAP Eternal.otf";
import "./App.css";

const App = () => {
  const [isNavOpen, setIsNavOpen] = useState(false); // State to manage the open/close state of the navigation menu

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevents the default behavior of the browser

    const targetId = event.target.getAttribute("href");
    const targetElement = document.querySelector(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div>
        <div className="container">
          {/* Navigation menu */}
          <Navigation isNavOpen={isNavOpen} toggleNav={toggleNav} />
          <Menu />
        </div>

        {/* Main content */}
        <main>
          {/* Parallax container */}
          <section id="home">
            <div className="parallax-container">
              {/* Parallax background images */}
              <div className="home"></div>

              {/* Content section */}
              <div className="content">
                <h1>
                  This isn't your powdered box drum kit that you mix yourself
                </h1>
                <p>
                  Our products are handcrafted in our shops in small batch
                  quantities like craft beer
                </p>
              </div>
            </div>
          </section>
        </main>

        {/* Our History section */}
        <section id="about">
          <div className="container">
            <p className="about-title">
              <strong>Our History</strong>
            </p>
            <p className="about-paragraph">
              Cleaning Equipment & Repair Company is a locally owned business out
              of Richmond, Virginia. CEARCO has been established since 1981. We
              make specialty blended cleaning solutions that are not powdered-
              based. All of our specialty blended solutions are liquid-based. With
              this said, we have remained faithful to the original formulas.
            </p>
          </div>
        </section>

        {/* Product content section */}
        <section id="product">
          <div class="container">
            <p className="product-paragraph">
              Designed for the professional detailer to have a superior one of a
              kind product. The quality of these products speak for themselves.
            </p>
          </div>

          {/* Render ProductTable component */}
          <Card />

          <div class="container">
                  <h6 class="about-title">Products & More</h6>
                  <p class="about-paragraph">
                    <p>CEARCO is an authorized sales & service center for ALKOTA, WITCO, ALL AMERICAN and ELECTRO MAGIC hot & cold water pressure washers.</p>
                    <p>ALKOTA CLEANING SYSTEM will be phasing out ALL AMERICAN & ELECTRO MAGIC pressure washers. CEARCO will still have available parts & accessories for these brands.</p>
                    <p>
                      We stock a variety of parts and accessories for an array
                      of power washers.
                    </p>
                    <p>
                      We offer other products for specific cleaning needs, these
                      may or may not be available to ship.
                    </p>
                    <p>
                      For local sales only
                    </p>
                    <p>
                      Bleach, Tar & Asphalt Remover (T & A 67) and Muriatic Acid
                    </p>
                    <p>
                      <center>Come wash with us!</center>
                    </p>
                  </p>
                </div>

        </section>

        {/* Footer */}
        <section id="contact">
          <footer class="site-footer">
            <div class="container">
              <div class="row">
                <div class="col-md offset-md-1">
                  <h6>Quick Links</h6>
                  <ul class="footer-links">
                    <li>
                      <a href="#home" onClick={handleLinkClick}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about" onClick={handleLinkClick}>
                        Our History
                      </a>
                    </li>
                    <li>
                      <a href="#product" onClick={handleLinkClick}>
                        Products & Services
                      </a>
                    </li>
                    <li>
                      <a href="#contact" onClick={handleLinkClick}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-md">
                  <h6>Contact Us</h6>
                  <p>
                    <FaMapMarker /> 117 W 21st St, Richmond, VA. United States{" "}
                    <br />
                  </p>
                  <p>
                    <FaMailBulk />{" "}
                    <a href="mailto:cearcochemicals@gmail.com">
                      cearcochemicals<br />@gmail.com
                    </a>{" "}
                    <br />
                  </p>
                  <FaPhone /> SHOP: <a href="tel:18042324895">(804) 232-4895</a> <br></br>
                  <FaPhone /> RAYMOND: <a href="tel:18045888943">(804) 588-8943</a> <br></br>
                  <FaPhone /> SARAH <a href="tel:18043323438">(804) 332-3438</a>
                  <ul class="social-icons">
                    <li>
                      <Link
                        to="https://www.instagram.com/cearco_chemicals"
                        target="_blank"
                      >
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.facebook.com/profile.php?id=100092584929609"
                        target="_blank"
                      >
                        <FaFacebook />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <ScrollToTopButton />
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-18 col-sm-20">
                  <p class="copyright-text">
                    &copy; 2024 CEARCO
                  </p>
                </div>
              </div>
            </div>
          </footer>
          {/* End footer */}
        </section>
      </div>
    </Router>
  );
};

export default App;
