import React, { useState, useEffect } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      id="back-to-top-btn"
      className={`back-to-top-btn ${showButton ? "show" : ""}`}
      onClick={scrollToTop}
      style={{ display: showButton ? "block" : "none", position: "fixed", bottom: "30px", right: "30px" }}
    >
      <FaArrowAltCircleUp />
    </button>
  );
};

export default ScrollToTopButton;
