import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

// Assets 
import IGLogo from "../../src/img/ig_logo.png";
import FreedomOne from "../../src/img/freedom_degreaserlabel.jpg";
import FreedomWash from "../../src/img/freedom1_tirewash.jpg";
import IrishOrange from "../../src/img/irishorange_citruswashlabel.jpg";
import RubyRed from "../../src/img/rubyred_tireshinelabel.jpg";
import BRC549 from "../../src/img/brc-549_washlabel.jpg";
import AlumBrighter from "../../src/img/aluminum_brighter.jpg";
import CEARCOLabel from "../../src/img/CEARCO_label.jpg";

function Card({
  titleOne = "Freedom 1 Degreaser",
  titleTwo = "Freedom 1 Tire Wash",
  titleThree = "Irish Orange Citrus Wash",
  titleFour = "BRC-549 (Roof & Deck Cleaner)",
  titleFive = "Aluminum Brightener",
  titleFivePointFive = "Ruby Red Tire Shine",
  titleSix = "FenderWell",
  titleSeven = "SCC-44 (Red Soap)",
  titleEight = "TW-23-C (Floor Soap)",
  titleNine = "TW-23 (Truck Wash)",
  titleTen = "Muriatic Acid",
  titleEleven = "Commercial Grade Bleach (12.5%)",
  titleTwelve = "HW 15 (House Wash)",
  titleThirteen = "Citrus 41",
  titleFourteen = "T & A 67 (Tar & Asphalt Remover)",
  descriptionOne = "Freedom 1 is a powerful concentrated liquid detergent that can be used in a variety of cleaning applications, including the cleaning of tires and fenderwells during detailing of personal vehicles.",
  descriptionTwo = "Freedom 1 is a powerful concentrated liquid detergent that can be used in a variety of cleaning applications, including the cleaning of tires and fenderwells during detailing of personal vehicles.",
  descriptionThree = "Irish Orange is a mild, citrus-based cleaning solution suitable for wiping down various surfaces in the interior of an auto, truck or SUV.",
  descriptionFour = "Our most aggressive cleaner is used in any application that doesn't necessarily allow high-pressure washing, as in roofs and other surfaces that could be damaged by high pressure.",
  descriptionFive = "AB-230 is a strong blend of different acids designed to clean non-polished aluminum surfaces and removal of rust.",
  descriptionFivePointFive = "An excellent, long lasting tire dressing and vinyl conditioner.",
  descriptionSix = "This is a product designed to enhance the fenderwell area and plastic bumpers that are scratched or hard to keep clean.",
  descriptionSeven = "Heavy-duty corrosive material used in degreasing heavy equipment and floors. It's not recommended for use on surfaces that are polished or have a high finish.",
  descriptionEight = "Medium duty alkaline based soap is used primarily by floor machine to clean various surfaces of floors.",
  descriptionNine = "Medium duty alkaline based truck wash used in the cleaning of vehicles & equipment that requires brushing. ",
  descriptionTen = "This product is a highly corrosive material used in cleaning newly poured concrete or brick. It's used in the removal of excess mortar from freshly-layed brick.",
  descriptionEleven = "This product is double the strength of household bleach it can be used for swimming pools and disinfecting any equipment and surfaces.",
  descriptionTwelve = "This product is an alkaline blend of a general-purpose cleaner and sodium hypochlorite used in the cleaning of surfaces with mold & mildew build-up.",
  descriptionThirteen =   "Citrus 41 is a polish safe non-caustic formula with a nice sudsy blend for cars & trucks, motorcycles to big rigs. ",
  descriptionFourteen = " Product info coming soon..."
  
}) {
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);
  const [openFive, setOpenFive] = useState(false);
  const [openFivePointFive, setOpenFivePointFive] = useState(false);
  const [openSix, setOpenSix] = useState(false);
  const [openSeven, setOpenSeven] = useState(false);
  const [openEight, setOpenEight] = useState(false);
  const [openNine, setOpenNine] = useState(false);
  const [openTen, setOpenTen] = useState(false);
  const [openEleven, setOpenEleven] = useState(false);
  const [openTwelve, setOpenTwelve] = useState(false);
  const [openThirteen, setOpenThirteen] = useState(false);
  const [openFourteen, setOpenFourteen] = useState(false);



  const theme = createMuiTheme({
    typography: {
      fontFamily: '"AMCAP Eternal", sans-serif',
    },
  });

  const handleOpenOne = () => {
    setOpenOne(true);
  };

  const handleCloseOne = () => {
    setOpenOne(false);
  };

  const handleOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };

  const handleOpenThree = () => {
    setOpenThree(true);
  };

  const handleCloseThree = () => {
    setOpenThree(false);
  };

  const handleOpenFour = () => {
    setOpenFour(true);
  };

  const handleCloseFour = () => {
    setOpenFour(false);
  };

  const handleOpenFive = () => {
    setOpenFive(true);
  };

  const handleCloseFive = () => {
    setOpenFive(false);
  };

  const handleOpenFivePointFive = () => {
    setOpenFivePointFive(true);
  }

  const handleCloseFivePointFive = () => {
    setOpenFivePointFive(false);
  }

  const handleOpenSix = () => {
    setOpenSix(true);
  };

  const handleCloseSix = () => {
    setOpenSix(false);
  };

  const handleOpenSeven = () => {
    setOpenSeven(true);
  }

  const handleCloseSeven = () => {
    setOpenSeven(false);
  }


  const handleOpenEight = () => {
    setOpenEight(true);
  }

  const handleCloseEight = () => {
    setOpenEight(false);
  }

  const handleOpenNine = () => {
    setOpenNine(true);
  }

  const handleCloseNine = () => {
    setOpenNine(false);
  }

  const handleOpenTen = () => {
    setOpenTen(true);
  }

  const handleCloseTen = () => {
    setOpenTen(false);
  }

  const handleOpenEleven = () => {
    setOpenEleven(true);
  }

  const handleCloseEleven = () => {
    setOpenEleven(false);
  }

  const handleOpenTwelve = () => {
    setOpenTwelve(true);
  }

  const handleCloseTwelve = () => {
    setOpenTwelve(false);
  }

  const handleOpenThirteen = () => {
    setOpenThirteen(true);
  }

  const handleCloseThirteen = () => {
    setOpenThirteen(false);
  }

  const handleOpenFourteen = () => {
    setOpenFourteen(true);
  }

  const handleCloseFourteen = () => {
    setOpenFourteen(false);
  }



  return (
    <div className="card-table">

      {/* Card One */}
      <div className="card">
        <img src={FreedomOne} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleOne}</h2>
          <p>{descriptionOne}</p>
          <div className="read-more-button">
            <Button variant="contained" color="primary" onClick={handleOpenOne}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openOne} onClose={handleCloseOne}>
          <ThemeProvider theme={theme}>
            <DialogTitle>{titleOne}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionOne}</p>
            <p>The product is also widely used in truck washing, restaurant hood cleaning, heavy equipment cleaning and general degreasing.</p>
            <p>
              <b>Directions:</b> Apply <b>Freedom 1</b> using a pressure
              washer or hand sprayer. Dilute 1 part <b>Freedom 1</b> with
              10-20 parts water, depending on the level of cleaning
              required. Apply the solution to the surface, allow it to dwell
              for a few minutes, and then rinse thoroughly with water.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseOne}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>



      {/* Card Two */}
      <div className="card">
        <img src={FreedomWash} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleTwo}</h2>
          <p>{descriptionTwo}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenTwo}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openTwo} onClose={handleCloseTwo}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleTwo}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionTwo}</p>
            <p>The product is also widely used in truck washing, restaurant hood cleaning, heavy equipment cleaning and general degreasing.</p>
            <p>
              <b>Directions:</b> Apply <b>Freedom 1</b> by spray bottle, tank,
              injector, brush or through a power washer. Depending upon the
              requirements; this product can be diluted 40 to 1 and remain an
              effective in the removal of dirt, grease and grime, road film
              and brake dust.
            </p>
            <p>
              <b>TIRE WASH & PREP</b>
            </p>
            <p>
              <b>Caution:</b> Avoid contact with polished aluminium or
              magnesium wheels. If the product becomes in contact with the
              wheel, rinse immediately. Prolonged contact can cause etching to
              the polished surface. Contents include Sodium Hydroxide, a
              strong corrosive to alkaline materials.
            </p>
            <p>
              <b>Precaución:</b> Evite el contacto con el aluminio pulido o
              magnesio ruedas, si una parte del material entra en contacto con
              la rueda, lavense inmediata. El contacto puede causar corrosión
              en la superficie pulida. Los contenidos incluyen hidróxio do
              sodio, un material furete alcalinas corrosivas.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseTwo}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>



      {/* Card Three */}
      <div className="card">
        <img src={IrishOrange} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleThree}</h2>
          <p>{descriptionThree}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenThree}>
              Read More
            </Button>
          </div>
        </div>
        <Dialog open={openThree} onClose={handleCloseThree}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleThree}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionThree}</p>
            <p>It works well as a shampoo for all rugs and carpets in the floorboard of your vehicles. It can also be used to wash exterior painted or polished surfaces with excellent results.</p>
            <p>
              <b>CITRUS WASH</b>
            </p>
            <p>
              <li>Citrus 113 Light Duty Truck Wash</li>
              <li>Citrus 212 Medium Duty Truck Wash</li>
            </p>
            <p>
              <b>Directions:</b> Apply <b>Irish Orange</b> by spray bottle or
              wipe / pad. Because this product is mild and is a blend of
              surfactants along with non-corrosive additives, some agitation
              and rubbing may be needed during application. This product
              leaves behind a pleasant scent after cleaning.
            </p>
            <p>
              <b>Note:</b> Wear rubber gloves when using this product as it
              contains D-Limonene, a citrus solvent made from processing
              grapefruit, oranges and other citrus fruits.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseThree}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>

      {/* Card Four */}
      <div className="card">
        <img src={BRC549} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleFour}</h2>
          <p>{descriptionFour}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenFour}>
              Read More
            </Button>
          </div>
        </div>
        <Dialog open={openFour} onClose={handleCloseFivePointFive}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleFour}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionFour}</p>
            <p>
              BRC-549 is also effective in any type of heavy-duty degreasing and may be used in graffiti removal.
            </p>
            <p><b>CAUTION:</b> When attempting to remove graffiti or any other material from a surface always do a test spot to ensure the product will do the job and not damage the surface.</p>
           
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseFour}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>


      {/* Card Five */}
      <div className="card">
        <img src={AlumBrighter} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleFive}</h2>
          <p>{descriptionFive}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenFive}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openFive} onClose={handleCloseFive}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleFive}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionFive}</p>
            <p>AB-230 can also be used to clean concrete and aggregate surfaces.</p>
            <p>
              <b>Directions:</b> Apply <b>AB-230</b> by spray bottle, pump
              sprayer, tank injector or brush. Depending on the requirements
              this product can be diluted up to 25 to 1 ano remain effective
              in the application.
            </p>
            <p>
              <b>Caution:</b> Avoid contact with skin, proper PPE should be
              worn. Avoid contact with polished surfaces. If the products
              comes in contact with polished surfaces rinse immediately.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseFive}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>

      {/* Card FivePointFive */}
      <div className="card">
      <img src={RubyRed} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleFivePointFive}</h2>
          <p>{descriptionFivePointFive}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenFivePointFive}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openFivePointFive} onClose={handleCloseFivePointFive}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleFivePointFive}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionFivePointFive}</p>
            <p>Ruby Red leaves tires and vinyl with a beautiful glossy surface that will not crack or dull.</p>
            <p>
              <b>Directions:</b> Apply to clean surface. Spray <b>Ruby Red</b>{" "}
              on surface and smooth out application with a soft cloth or
              sponge. Allow surface to dry for 10 to 15 minutes.
            </p>
            <p>
              <b>TIRE SHINE</b>
            </p>
            <p>
              <b>Caution:</b> Flammable Liquid, contains petroleum solvent.
              Keep away from heat, sparks or open flames. Harmful or fatal if
              swallowed. Avoid prolonged exposure to skin and lungs. Do not
              induce vomiting, get medical attention immediately. Keep out of
              reach of children.
            </p>
            <p>
              <b>Precaución:</b> Liquido inflamable, contiene solventes
              derivados del petróleo. Mantener alejado del calor, chispas o
              llamas abiertas. Daino o fatal si se pulmones. No provocar el
              vómito, busque atención médica inmediatmente. Mantener fuera del
              alcance de los niños.
            </p>
            <p>
              <b>Guarantee:</b> Satisfaction Guaranteed, manufacturer’s
              liability is limited to refund of purchase price when unused.
              Proof of Purchase is required and must be returned within 30
              days.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseFivePointFive}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>


      {/* Card Six */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleSix}</h2>
          <p>{descriptionSix}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenSix}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openSix} onClose={handleOpenSix}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleSix}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionSix} </p>
            <p>This product will assist with providing a lusterous finish.</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
            <div className="read-more-button">
              <p>{/* Read More Button will go here */}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseSix}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>



      {/* Card Seven */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleSeven}</h2>
          <p>{descriptionSeven}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenSeven}>
              Read More
            </Button>
          </div>
        </div>
      


        <Dialog open={openSeven} onClose={handleOpenSeven}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleSeven}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionSeven} </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseSeven}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>



      {/* Card Eight */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleEight}</h2>
          <p>{descriptionEight} </p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenEight}>
              Read More
            </Button>
          </div>
        </div>

        <Dialog open={openEight} onClose={handleOpenEight}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleEight}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionEight}</p>
            <p>This product will assist with providing a lusterous finish.</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseEight}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>


      {/* Card Nine */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleNine}</h2>
          <p>{descriptionNine}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenNine}>
              Read More
            </Button>
          </div>
        </div>
      


        <Dialog open={openNine} onClose={handleOpenNine}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleNine}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{/* Description will go here */}
              <p>{descriptionNine}</p>
            </p>
            <p></p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseNine}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>

      {/* Card Ten */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleTen}</h2>
          <p>{descriptionTen}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenTen}>
              Read More
            </Button>
          </div>
        </div>
      

        <Dialog open={openTen} onClose={handleCloseTen}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleTen}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionTen}</p>
            <p>
             It can also be used in the removal of alkaline build up's created by the use of calcium hypochlorite, in maintenance of pools. This is not a shippable product.
            </p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseTen}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>



      {/* Card Eleven */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleEleven}</h2>
          <p>{descriptionEleven}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenEleven}>
              Read More
            </Button>
          </div>
        </div>
      

        <Dialog open={openEleven} onClose={handleCloseEleven}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleEleven}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionEleven}</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseEleven}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
        </div>


      {/* Card Twelve */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleTwelve}</h2>
          <p>{descriptionTwelve}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenTwelve}>
              Read More
            </Button>
          </div>
        </div>
      

        <Dialog open={openTwelve} onClose={handleCloseTwelve}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleTwelve}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionTwelve}</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseTwelve}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
        </div>


      {/* Card Thirteen */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleThirteen}</h2>
          <p>{descriptionThirteen} </p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenThirteen}>
              Read More
            </Button>
          </div>
        </div>
      

        <Dialog open={openThirteen} onClose={handleCloseThirteen}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleThirteen}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionThirteen} </p>
            <p> This product is free rinsing and will not cause any issues with dulling the luster of the surface being washed.</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseThirteen}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
        </div>

      {/* Card Fourteen */}
      <div className="card">
        <img src={CEARCOLabel} alt="Card" className="card-image" />
        <div className="card-content">
          <h2>{titleFourteen}</h2>
          <p>{descriptionFourteen}</p>
          <div className="read-more-button">
            <Button variant="contained" onClick={handleOpenFourteen}>
              Read More
            </Button>
          </div>
        </div>
    


        <Dialog open={openFourteen} onClose={handleCloseFourteen}>
          <ThemeProvider theme={theme}>
            <DialogTitle className="card-title">{titleFourteen}</DialogTitle>
          </ThemeProvider>
          <DialogContent>
            <p>{descriptionFourteen}</p>
            <p>
              Product manufactured by CEARCO Inc.
              <br />
              Richmond, Virginia.
            </p>
            <p>
              cearcochemicals@gmail.com
              <br />
              <img src={IGLogo} alt="IG" /> cearco_chemicals
            </p>
          </DialogContent>
          <DialogActions>
            <ThemeProvider theme={theme}>
              <Button onClick={handleCloseFourteen}>Close</Button>
            </ThemeProvider>
          </DialogActions>
        </Dialog>
      </div>
    </div>  

  );
}

export default Card;
